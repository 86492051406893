import React, { useEffect } from "react";
import { Box, Container, Typography, Grid, Card, Avatar, Button, Link } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPortfolios } from "../redux/portfoliosSlice";
import { ImageBaseUrl } from "../Network/constant";

const ProfileListScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const service = location.state?.service;

    const portfolios = useSelector((state) => state.portfolios.portfolios);

    // console.log("portfolio====>", portfolios);

    // console.log("service", service);

    useEffect(() => {
        dispatch(fetchPortfolios());
    }, [dispatch]);

    const uniqueCompanies = {};
    const filteredPortfolios = portfolios.filter(portfolio => {
        const hasMatchingCategory = portfolio?.company?.userCategories?.some(
            category => category?.categoryId === service
        );

        if (hasMatchingCategory && !uniqueCompanies[portfolio.company.id]) {
            uniqueCompanies[portfolio.company.id] = true;
            return true;
        }
        return false;
    });

    console.log("filteredPortfolios", filteredPortfolios);

    return (
        <Box sx={{ py: 8, backgroundColor: "#f5f5f5", textAlign: "center" }}>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
                    Available Contractors
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ color: "#555", mb: 4 }}>
                    Browse through our list of professional contractors ready to help with your project.
                </Typography>

                <Grid container spacing={4}>
                    {filteredPortfolios.map((portfolio) => (
                        <Grid item xs={12} sm={6} md={4} key={portfolio.id}>
                            <Card
                                sx={{
                                    backgroundImage: "linear-gradient(to right, #3a7bd5, #3a6073)",
                                    color: "#fff",
                                    textAlign: "center",
                                    height: "100%",
                                    boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                                    transition: "transform 0.3s",
                                    "&:hover": {
                                        transform: "scale(1.05)",
                                    },
                                    position: "relative",
                                }}
                            >
                                <Avatar
                                    src={
                                        portfolio?.company?.image?.length > 0
                                            ? `${ImageBaseUrl}/${portfolio.company.image}`
                                            : "https://via.placeholder.com/150"
                                    }
                                    alt={portfolio.title}
                                    sx={{
                                        width: 110,
                                        height: 110,
                                        margin: "0 auto 16px",
                                        marginTop: "20px",
                                        boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                                    }}
                                />
                                <Typography variant="h6" gutterBottom>
                                    {portfolio.company.name}
                                </Typography>
                                {/* <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic' }}>
                                    {portfolio.company.category.name}
                                    </Typography> */}
                                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                    <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic' }}>
                                        {portfolio.company.userCategories
                                            .map((category) => category.category.name) // Extract names
                                            .join(", ")} {/* Join names with a comma and space */}
                                    </Typography>
                                </Box>

                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    {portfolio.youtubeLink}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    <Link sx={{
                                        color: "#fff",
                                        textDecoration: "none"
                                    }} href={`mailto:${portfolio.company.email}`} target="_blank" rel="noopener noreferrer">
                                        {portfolio.company.email}
                                    </Link>
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#1976d2",
                                        color: "#fff",
                                        mb: 2,
                                        "&:hover": {
                                            backgroundColor: "#1565c0",
                                        },
                                    }}
                                    onClick={() => navigate("/profileDetailScreen", { state: { portfolio } })}
                                >
                                    View Profile
                                </Button>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default ProfileListScreen;