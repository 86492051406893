import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Link,
  Button,
  Chip,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageBaseUrl } from "../Network/constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PortfolioDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { portfolio, nextPortfolio } = location?.state || {};
  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    fade: true,
  };

  console.log('portfolio', portfolio);

  return (
    <Box
      sx={{
        py: 10,
        backgroundImage: "linear-gradient(to right, #3a7bd5, #3a6073)",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="md">
        <Card
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0 8px 20px rgba(0,0,0,0.15)",
            borderRadius: "16px",
            overflow: "hidden",
            p: 4,
            transition: "transform 0.3s, box-shadow 0.3s",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0 12px 25px rgba(0,0,0,0.2)",
            },
          }}
        >
          <CardContent>
            {/* Portfolio Images Slider */}
            {portfolio?.portfolioImages?.length > 1 ? (
              <Slider {...settings}>
                {portfolio?.portfolioImages?.map((imageObj, index) => (
                  <Box key={index} sx={{ px: 1 }}>
                    <img
                      src={`${ImageBaseUrl}/${imageObj.image}`}
                      alt={`Portfolio ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "400px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                ))}
              </Slider>
            ) : (
              <Box sx={{ textAlign: "center", px: 1 }}>
                {portfolio?.portfolioImages?.map((imageObj, index) => (
                  <img
                    key={index}
                    src={`${ImageBaseUrl}/${imageObj.image}`}
                    alt={`Portfolio ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "400px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                ))}
              </Box>
            )}

            {/* Header */}
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                mt: 4,
                mb: 3,
                color: "#2D3748",
                textAlign: "center",
              }}
            >
              {portfolio?.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.1rem",
                mb: 4,
                color: "#555",
                lineHeight: 1.6,
                textAlign: "center",
              }}
            >
              {portfolio?.content}
            </Typography>

            {/* Company Information */}
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}
                >
                  Company Information
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="Price" secondary={`$${portfolio?.price}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Phone" secondary={portfolio?.phone} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Email"
                      secondary={
                        <Link
                          href={`mailto:${portfolio?.email}`}
                          sx={{
                            color: "#1976d2",
                            textDecoration: "none",
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          {portfolio?.email}
                        </Link>
                      }
                    />
                  </ListItem>
                {portfolio?.website && (
                  <ListItem>
                    <ListItemText
                      primary="Website"
                      secondary={
                        <Link
                          href={portfolio?.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: "#1976d2",
                            textDecoration: "none",
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          View More
                        </Link>
                      }
                    />
                  </ListItem>
                )}
                  {portfolio?.magzine && (
                    <ListItem>
                      <ListItemText primary="Magazine" secondary={
                        <Link
                          href={portfolio?.magzine}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: "#1976d2",
                            textDecoration: "none",
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          View More
                        </Link>

                      } />
                    </ListItem>
                  )}
                </List>
              </Grid>

              {/* Social Links */}
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}
                >
                  Connect with Us
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                  {portfolio?.facebookLink && (
                    <IconButton href={portfolio.facebookLink} target="_blank">
                      <FacebookIcon fontSize="large" sx={{ color: "#4267B2" }} />
                    </IconButton>
                  )}
                  {portfolio?.twitterLink && (
                    <IconButton href={portfolio.twitterLink} target="_blank">
                      <TwitterIcon fontSize="large" sx={{ color: "#1DA1F2" }} />
                    </IconButton>
                  )}
                  {portfolio?.linkedinLink && (
                    <IconButton href={portfolio.linkedinLink} target="_blank">
                      <LinkedInIcon fontSize="large" sx={{ color: "#0077b5" }} />
                    </IconButton>
                  )}
                  {portfolio?.instagramLink && (
                    <IconButton href={portfolio.instagramLink} target="_blank">
                      <InstagramIcon fontSize="large" sx={{ color: "#C13584" }} />
                    </IconButton>
                  )}
                </Box>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mb: 2  , mt : 4, color: "#1976d2" }}
                >
                  Address Information
                </Typography>
                <Typography variant="body1" sx={{ color: "#555" }}>
                  {portfolio?.youtubeLink}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mb: 2, mt : 4,  color: "#1976d2" }}
                >
                  City
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                  {portfolio?.portfolioCities?.map((city) => (
                    <Chip
                      key={city.city.id}
                      label={city.city.name}
                      sx={{
                        backgroundColor: "#e0f7fa",
                        color: "#00695c",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
            {/* Cities & Building Types */}
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}
                >
                  Specialization
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {portfolio?.portfolioBuildingTypes?.map((type) => (
                    <Chip
                      key={type.buildingType.id}
                      label={type.buildingType.name}
                      sx={{
                        backgroundColor: "#e8f5e9",
                        color: "#2e7d32",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>

            {/* Additional Info */}
            {portfolio?.magazine && (
              <Box sx={{ mt: 4 }}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}
                >
                  Additional Information
                </Typography>
                <Typography variant="body1" sx={{ color: "#555" }}>
                  <strong>Magazine:</strong> {portfolio?.magazine}
                </Typography>
              </Box>
            )}

            {/* Next Portfolio Button */}
            {nextPortfolio && (
              <Box sx={{ mt: 4, textAlign: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#165a9b" },
                    px: 4,
                    py: 2,
                    borderRadius: "8px",
                    fontSize: "1rem",
                  }}
                  onClick={() =>
                    navigate(`/portfolio/${nextPortfolio.id}`, {
                      state: { portfolio: nextPortfolio },
                    })
                  }
                >
                  Next Portfolio
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default PortfolioDetail;