import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  Button,
  CardContent,
  CardActions,
  TextField,
} from "@mui/material";
import { updateUserProfile } from '../redux/authSlice';
import ToastNotification, { showErrorToast , showSuccessToast } from "../components/ToastNotification";
import { useNavigate } from "react-router-dom";
import { useSelector , useDispatch } from "react-redux";

const ImageUploadPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    name: user?.name || user?.data?.name || '',
    email: user?.email || user?.data?.email || '',
    image : null
  });
  // console.log('formData' , formData)
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();
  const handleImageChange = (e) => {  
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.image) {
      showErrorToast("Please select an image to upload");
      return;
    }
    const updatedData = new FormData();
    updatedData.append('name', formData.name);
    updatedData.append('email', formData.email);
    updatedData.append('image', formData.image);
    // console.log('updatedDat----' , updatedData)
    // return;
    dispatch(updateUserProfile({ formData: updatedData  })).unwrap().then(() => {
      showSuccessToast("Image uploaded successfully");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }).catch(() => {
      showErrorToast("Image upload failed");
    });


    // setUploading(true);
    // const formData = new FormData();
    // formData.append("image", image);

    // try {
    //   const response = await fetch("/api/upload", {
    //     method: "POST",
    //     body: formData,
    //   });
      
    //   if (!response.ok) throw new Error("Upload failed");
    //   showSuccessToast("Image uploaded successfully");
    //   setTimeout(() => navigate("/"), 2000);
    // } catch (error) {
    //   showErrorToast(error.message);
    // } finally {
    //   setUploading(false);
    // }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(135deg, #01AEFE, #42a5f5)",
        padding: "50px 0",
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            background: "#fff",
            borderRadius: "16px",
            boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
            p: 4,
            textAlign: "center",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0 12px 30px rgba(0,0,0,0.3)",
            },
          }}
        >
          <CardContent>
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#1976d2", mb: 3 }}>
              Company Logo
            </Typography>

            {preview && (
              <Box
                component="img"
                src={preview}
                alt="Preview"
                sx={{ width: "100%", height: "auto", mb: 3, borderRadius: "8px" }}
              />
            )}

            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                fullWidth
                sx={{ mb: 3 }}
                required
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontWeight: "bold",
                  py: 1.5,
                  borderRadius: "8px",
                  fontSize: "1.2rem",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
              >
            Upload Image
              </Button>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "center", mt: 2 }}>
            {/* <Typography variant="h5"> */}
              {/* Want to go back? {" "} */}
              <Button
                sx={{
                  textTransform: "none",
                  color: "#1976d2",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },

                }}
                onClick={() => navigate("/login")}
              >
                Skip
              </Button>
            {/* </Typography> */}
          </CardActions>
        </Card>
      </Container>
      <ToastNotification />
    </Box>
  );
};

export default ImageUploadPage;